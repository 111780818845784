$space-divider: 2.5rem;
$color-mainblue: #01abfb;

.issue-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  min-width: 1rem;
  .Pane{
    display: flex;
    overflow: hidden;
  }
  .Pane2 {
    flex: 1;
    align-items: stretch;
  }

  .ais-select__placeholder{
    white-space: nowrap;
  }

  &-statusbar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    border-radius: .25rem;
    background-color: $gray1;
    border: .0125rem solid $gray4;
    padding: 1rem;
    &-item {
      margin: 0 .5rem;
      display: flex;
      flex: 1;
      min-width: 10rem;
      // max-width: 15rem;
      &.project {
        // min-width: 20rem;
        // max-width: none;
      }
    }
  }

  .issue-status-container{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;

    &-warning {
      position: absolute;
      font-size: 1.75rem;
      color: $mainorange;
      right:-1.25rem;
    }
  }

  &-preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &-not-found {
    align-self: center;
    margin: 1rem;
    color: $color-mainblue;
    font-size: 1rem;
    font-weight: 100;
    text-align: center;
  }

  &-wrapper {
    flex: 1;
    // flex-grow: 3;
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
  }
  &-body {
    padding: 2rem;
    flex: 1;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    overflow: auto;
    text-align: left;
    // height: 100%;

  }

  .block-divider {
    margin-bottom: $space-divider;
  }

  .flex-gap {
    display: inline-flex;
    flex-wrap: wrap;
    gap: $space-divider;
    align-items: stretch;    
  }

  .timeline{
    // margin: 0 -1.25rem;
    margin: 0;
    flex: 1;
  }


  .data-block{
    padding: 1rem $space-divider;
    border-radius: .25rem;
    background-color: $gray1;
    border: .0125rem solid $gray4;
  }

  .data-notes{
    background-color: $orange1;
    border: .0125rem solid $halforange;
    padding: 0;
  }

  .issue-notes{
    // margin: 0 1rem;
    max-height: 50rem;
    overflow: hidden;    
    padding-bottom: 1rem;
    &.is-editing{
      border-bottom: 0.0625rem dotted $halforange;
    }
    .issue-journal-header{
      height: 3.5rem;
      min-height: 3.5rem;
      background-color: $orange1;
      border-radius: 0.25rem;
      padding-left: 1.5rem;
      align-items: center;
      overflow: visible;
      .header-panel-text{
        font-size: .875rem;
        color: $gray8;
      }
    }
    .issue-journal-content {
      padding: 0 1.5rem;
      // overflow: hidden;
    }

    .journal-item-header{
      .delimiter-line {
        border-top-width: 0;
      }
    }
  }

  &-header{
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;

    &-left, &-right{
      // flex: 1;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
    }

    &-left {
      flex: 1;
    }

    &-right {
      // width: 12rem;
      // flex: 1;
      // min-width: 15rem;
      // max-width: 20rem;
      margin-left: 1rem;
    }

    &-title {
      display: block;
      line-height: 1.75rem;
      // padding-bottom: .5rem;
      text-align: left;

      font-size: 1.25rem;
      font-family: Arial;
      &-num {
        color: $gray7;
        margin-right: .5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        span{
          margin-right: .5rem;
        }
        svg{
          cursor: pointer;
          font-size: 2rem;
        }
      }

      &-subject {
        color: $dgray1;

        &.editable, &.editable:focus, &.editable:hover {
          border-radius: .25rem;
          padding: .5rem!important;
          border: .0625rem solid $gray4;
          outline: none;
        }
        &.invalid {
          // border: .0625rem solid $mainred;
          border: .0625rem solid $dred1!important;
        }
      }
    }
  }

  &-users, &-timedata{
    border-radius: .25rem;
    background-color: $gray1;
    border: .0125rem solid $gray4;
    padding: .5rem 1.5rem;
    flex: 1;
    // overflow: hidden;

  }

  // &-users {
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
    // align-items: stretch;
  // }


  &-timedata{
    flex: 1;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    // padding-top: 1.5rem;
    // overflow: hidden;


    .react-datepicker-popper{
      z-index: 15;
    }
    &-block {
      flex: 1;
      overflow: hidden;
      &:first-child{
        margin-right: 1.5rem;
      }

      &:last-child{
        .ais-input-row .label, .issue-datafield-label {
          min-width: 6.5rem;
        }

      }

      .issue-datafield-label {
        min-width: 8.5rem;
      }

      .ais-input-row {
        position: relative;
        .label {
          min-width: 8.5rem;
        }

        input {
          min-width: 2rem;
        }
      }
    }
  }

  &-description{
    color: $dgray5;
    background-color: $grayblue2!important;
    border: .0125rem solid $grayblue4 !important;
    text-align: left;
  }

  &-attributes {
    background: $violet1!important;
  }

  &-related-issues {
    background: $green1!important; 
    border-color: $halfgreen!important;
  }

  &-buttons {
    flex: 1;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: -.5rem;
    .ais-button {
      border-radius: .25rem;
    }

  }

  .invalid {
    input{
      border: .0625rem solid $mainred;
    }
  }

  .ais-select__control {
    &.invalid {
      border: .0625rem solid $mainred;
    }
  }
}
