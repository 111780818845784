@import "variables";
.profile-tool{
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  &-body {
    display: flex;
    flex: 1;
    flex-direction: row;
    background: $white;
    overflow: hidden;
    position: relative;
    width: 100%;
    text-align: left;
    .react-tabs__tab-list{
      padding: .25rem 0;
    }
  }

  @import "../../components/css";
}