.user-form{
  flex: 1;
  overflow: auto;
  margin: 1rem;
  max-width: 80rem;
  &-body{
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    &-left, &-right{
      flex: 1;
      display: flex;
      flex-direction: column;
      min-width: 18rem;
      margin: 1rem;
    }

    &-left{
      align-items: center;
    }

    &-right{
      align-items: stretch;
    }

    .user-login, .user-name {
      margin: .5rem;
    }

    .user-name {
      font-size: 1.5rem;
      text-align: center;
      color: $dgrayblue3;
    }

    .user-login {
      font-size: 1rem;
      text-align: center;
      color: $dgrayblue1;
    }

    .user-groups-wrapper{
      padding-top: .5rem;
      text-align: left;

      &-title {
        font-weight: 500;
        color: $dgrayblue3;
        // font-size: 1.25rem;
      }

      &-values {
        font-size: 0.875rem;
        color: $dgrayblue1;
      }

    }
  }

  &-header{
    font-size: 1.5rem;
    padding-bottom: .5rem;
    color: $dgrayblue3;
  }

  &-footer{
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    // align-items: flex-end;
    justify-content: flex-end;;
  }

  &-photo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    // margin: auto;

    &-img-wrapper {
      width: 10rem;
      height: 10rem;


      border-radius: .5rem;
      border: 0.0625rem solid $gray4;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      &.empty {
        background: url("../../../../../public/images/camera.png") 50% 50% no-repeat;
        background-position: center;
        background-size: 70%;
      }

      img{
        width: 100%;
        height: auto;
        object-fit: cover;
      }

      &.required{
        border: 0.0625rem solid $mainred;
      }
    }

    &-img {
      width: 100%;
      height: auto;
    }

    &-input {
      display: none;
    }

    &-browse {
      white-space: nowrap;
      text-align: center;
      font-size: .85rem;
      margin: .25rem 0;
      // color: $mainblue;
      color: $dblue1;
      text-decoration: none;
    }
  }
}