@import "./indicator.scss";
@import "./target.scss";
@import "./ais-object.scss";

.toolbar {
  height: 2.5rem;
  .uif-components-button.no-text {
    padding: 0;
    width: 2.75rem;
    max-width: 3rem;
  }
  .uif-components-button {
    height: 2.5rem;
    span {
      padding-left: 0.25rem;
      white-space: nowrap;
    }
  }
}

progress {
  accent-color: #03a9f4;
  min-height: 1.5rem;
  flex: 1;
  margin: 0 0.5rem;
}

progress[value] {
  --color: #40acfb; /* the progress color */
  --background: lightgrey; /* the background color */
  --radius: 0.25rem;

  -webkit-appearance: none;
  -moz-appearance: none;

  accent-color: #03a9f4;
  min-height: 1rem;
  flex: 1;
  margin: 0 0.5rem;

  appearance: none;
  border: none;
  border-radius: var(--radius);
  background: var(--background);
}
progress[value]::-webkit-progress-bar {
  border-radius: var(--radius);
  background: var(--background);
}
progress[value]::-webkit-progress-value {
  border-radius: var(--radius);
  background: var(--color);
}
progress[value]::-moz-progress-bar {
  border-radius: var(--radius);
  background: var(--color);
}

.progress-text {
  line-height: initial;
  font-family: Helvetica, Roboto, Arial, sans-serif !important;
  display: flex;
  align-items: center;
  font-size: 1rem;
  flex-direction: row;
  padding: 0.5rem;
}
